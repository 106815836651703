import appConfig from 'appConfig';
import axios from 'axios';
import { TOAST_TYPE } from 'components/Toast/constants';
import { showToastFunction } from 'components/Toast/utils';
import { LOCAL_STORAGE } from 'constants/appConstants';
import { APP_ROUTES } from 'constants/routeConstants';
import { REMEMBER_ME } from 'pages/Login/LoginForm/constants';
import { chooseErrorMessage } from 'utils/chooseErrorMessage';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'utils/localStorage';

// Global Axios settings, such as baseURL, headers, etc.
const instance = axios.create({
  baseURL: appConfig.BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.mx.api.v1+json',
  },
});

const handleLogout = () => {
  try {
    const rememberMe = +getLocalStorageItem(LOCAL_STORAGE.REMEMBER_ME);
    if (rememberMe === REMEMBER_ME.OFF) {
      setLocalStorageItem(LOCAL_STORAGE.REMEMBER_ME, REMEMBER_ME.OFF);
      removeLocalStorageItem(LOCAL_STORAGE.LOGIN_DETAILS);
    }
    removeLocalStorageItem(LOCAL_STORAGE.USER_DETAILS);
    setTimeout(() => {
      window.location.pathname = APP_ROUTES.LOGIN;
    }, 2000);
  } catch (error) {
    console.log(error);
  }
};

// Request Interceptor
instance.interceptors.request.use(
  (config) => {
    const { token = '' } =
      getLocalStorageItem(LOCAL_STORAGE.USER_DETAILS) || {};
    if (token) config.headers['authentication'] = token;
    // Perform any request-related modifications, such as adding an authorization token
    // You can access the Redux store or other state management here if needed
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response Interceptor
instance.interceptors.response.use(
  (response) => {
    // Handle successful responses, e.g., update UI or extract data
    return response?.data;
  },
  (error) => {
    if (error?.response?.data?.statusCode === 403) {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage)
        showToastFunction({
          type: TOAST_TYPE.ERROR,
          message: chooseErrorMessage(errorMessage),
        });
      handleLogout();
    }
    // showToastFunction({ type: TOAST_TYPE.ERROR, message: error?.message });
    // Handle errors, e.g., show error messages or redirect to an error page
    return Promise.reject(error);
  },
);

export default instance;
