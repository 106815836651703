/* eslint-disable camelcase */
import { useGlobalContext } from 'context/GlobalContext/useGlobalContext';
import { useEffect } from 'react';
import { Userpilot } from 'userpilot';

const UserIdentification = () => {
  const { state = {} } = useGlobalContext();
  const { userDetails = {} } = { ...state };
  const { firstName = '', lastName = '', email = '' } = { ...userDetails };

  const displayName = `${firstName} ${lastName}`;

  useEffect(() => {
    Userpilot.identify(email, {
      name: displayName,
      email: email,
      created_at: new Date().toISOString(),
    });
  }, [displayName, email]);

  return null; // This component does not render anything
};

export default UserIdentification;
